import { createRouter, createWebHistory } from 'vue-router'
// import { G } from "@/_common";
import NProgress from 'nprogress';


const routes = [
    // {
    //     path: '/',
    //     name: 'index',
    //     component: () => import('@/layouts/Main.vue'),
    //     props: { data: { page: "Default", data: {} } },
    //     // meta: { requireAuth: true },
    // },
    // {
    //     path: '/heroes',
    //     name: 'heroes',
    //     component: () => import('@/layouts/Main.vue'),
    //     props: { data: { page: "Heroes" } },
    //     // meta: { requireAuth: true },
    // },
    // {
    //     path: '/info/:id',
    //     name: 'info',
    //     component: () => import('@/layouts/Main.vue'),
    //     props: { data: { page: "Info", data: {} } },
    //     // props: (route) => { data: { page: "About", data: {} } },
    //     // meta: { requireAuth: true },
    // },
    {
        path: '/:lang([a-z]{2})',
        name: 'lang',
        component: () => import('@/App.vue'),
        // props: { data: { page: "Default", data: {} } },
        // meta: { requireAuth: true },
        children: [
            {
                path: '',
                // alias: '/',
                name: 'index',
                component: () => import('@/layouts/Main.vue'),
                props: { data: { page: "Default" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'infom/:id',
                name: 'infom',
                component: () => import('@/layouts/Main.vue'),
                props: { data: { page: "InfoMartyrs" } },
                // props: (route) => { data: { page: "About", data: {} } },
                // meta: { requireAuth: true },
            },
            {
                path: 'infov/:id',
                name: 'infov',
                component: () => import('@/layouts/Main.vue'),
                props: { data: { page: "InfoVeterans" } },
                // props: (route) => { data: { page: "About", data: {} } },
                // meta: { requireAuth: true },
            },
            {
                path: 'martyrs/:page/:perpage/:soldier_id/:rank_id/:battle_place_id/:medal_id/:birth_place_id/:family_type_id/:education_type_id/:troop_type_id/:full_name?',
                // path: 'martyrs/:page/:perpage/:soldier_id/:rank_id/:battle_place_id/:medal_id/:birth_place_id/:family_type_id/:death_place_id/:troop_type_id/:full_name?',
                name: 'martyrs_by_page',
                component: () => import('@/layouts/Main1.vue'),
                props: { data: { page: "Martyrs" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'martyrs/:soldier_id',
                name: 'martyrs_by_type',
                component: () => import('@/layouts/Main1.vue'),
                props: { data: { page: "Martyrs" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'martyrs',
                name: 'martyrs',
                component: () => import('@/layouts/Main1.vue'),
                props: { data: { page: "Martyrs" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'veterans/:page/:perpage/:soldier_id/:rank_id/:battle_place_id/:medal_id/:birth_place_id/:family_type_id/:education_type_id/:troop_type_id/:full_name?',
                name: 'veterans_by_page',
                component: () => import('@/layouts/Main1.vue'),
                props: { data: { page: "Veterans" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'veterans/:soldier_id',
                name: 'veterans_by_type',
                component: () => import('@/layouts/Main1.vue'),
                props: { data: { page: "Veterans" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'veterans',
                name: 'veterans',
                component: () => import('@/layouts/Main1.vue'),
                props: { data: { page: "Veterans" } },
                // meta: { requireAuth: true },
            },
            // {
            //     path: 'chronology/:id/:article_id',
            //     name: 'chronology_date_article',
            //     component: () => import('@/layouts/Chronology.vue'),
            //     props: { data: { page: "Chronology" } },
            //     // meta: { requireAuth: true },
            // },
            {
                path: 'chronology/:id',
                name: 'chronology_date',
                component: () => import('@/layouts/Chronology.vue'),
                props: { data: { page: "Chronology" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'chronology',
                name: 'chronology',
                component: () => import('@/layouts/Chronology.vue'),
                props: { data: { page: "Chronology" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'contact/:id',
                name: 'contact_id',
                component: () => import('@/layouts/Main.vue'),
                props: { data: { page: "Contact" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import('@/layouts/Main.vue'),
                props: { data: { page: "Contact" } },
                // meta: { requireAuth: true },
            },
            {
                path: 'about',
                name: 'about',
                component: () => import('@/layouts/Main.vue'),
                props: { data: { page: "About" } },
                // meta: { requireAuth: true },
            },
            {
                path: ':catchAll(.*)',
                name: 'notfound1',
                component: () => import('@/layouts/404.vue'),
                props: { data: { page: "NotFound" } },
                // meta: { requireAuth: true },
            },
        ]
    },
    {
        path: '/:catchAll(.*)',
        name: 'notfound',
        component: () => import('@/layouts/404.vue'),
        props: { data: { page: "NotFound" } },
        // meta: { requireAuth: true },
    },
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory(),
    // base: '/vuef/', //process.env.BASE_URL,
    // template: '<App/>',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { left: 0, top: 0 }
    }

})

router.beforeEach((to, from, next) => {
    if (from.fullPath.length > 2 && from.fullPath.slice(0, 3) != to.fullPath.slice(0, 3)) {
        let translatedPath = from.fullPath.replace(from.fullPath.slice(0, 3), to.fullPath.slice(0, 3));
        from.fullPath = translatedPath;
        from.path = translatedPath;
        from.href = translatedPath;
        next({ path: translatedPath, query: to.query, hash: to.hash, params: {lang: to.params.lang} });
    }
    else if (to.path == '/') {
        next({
            path: 'az',
            // force: true,
            // query: { redirect: to.fullPath },
        });
    }
    else
        next();
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start()
        next()
    } else {
        next()
    }
});

router.afterEach((to, from) => {
    NProgress.done()
});


export default router
