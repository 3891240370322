import { ref } from 'vue';
import axios from 'axios';
import NProgress from 'nprogress';
import { useStore } from "vuex";

export const G = {
    // host: `https://unudulmazlar.az`,
    // host: `http://localhost:8080`,
    apiBaseUrl: `https://backend.unudulmazlar.az/api/v1`,
    mediaURL: `https://unudulmazlar.az/media/`,
    heroLoadInterval: 10000,

    api: {
        languages:              'language/all',
        menus:                  'menu/{lang}/client/all',
        translations:           'language/translations/{lang}',
        soldierRandom:          'soldier/{lang}/random-soldiers',
        soldierFullName:        'soldier/{lang}/all-soldiers-fullnames',
        soldierBirthday:        'soldier/{lang}/today-birthdays',
        soldierDetail:          'soldier/{lang}/detail/{soldier_id}',
        soldierPlaces:          'soldier/{lang}/places',

        chronology44days:       'chronology/{lang}/44days',
        chronologyContent:      'chronology/{lang}/client/{article_id}',
        chronologyTimes:        'chronology/{lang}/articles/{date_id}',

        soldierFilter:          'soldier/{lang}/soldier-filter',
        soldierTypes:           'info/{lang}/soldier-types/all',
        soldierBattlePlace:     'place/{lang}/battle-places/all/{soldier_type_id}',
        soldierRanks:           'rank/{lang}/client/all',

        // contact:                'contact/web',
        contact:                'contact/',
        footerUsefulLinks:      'useful-link/{lang}/client/all',
        footerContactInfo:      'contact-us-info/{lang}/client/all',
        // footerUsefulLinks:      'contact/useful-links',
        // footerContactInfo:      'contact/info',

        soldierBirthPlace:      'place/{lang}/birth-places/all',
        soldierMedals:          'medal/{lang}/client/all',
        soldierFamily:          'info/{lang}/family-types/all',
        soldierEducation:       'info/{lang}/education-types/all',
        soldierTroop:           'info/{lang}/troop-types/all',

        about_us:               'about_us/last/client/{lang}',
    },

    // Doyus tarixi        ( Tarix formati:   31.12.2020  )
    // Doguldugu yer       ( List )
    // Medallar            ( Siyahi )
    // Qosun novu          ( Siyahi )
    // Təhsili             ( Orta / Ali )
    // Ailə vəziyyəti      ( Subay / Evli )
    // Oxuduğu məktəb      ( List )
    // Ali təhsil müəssisəsi ( List )

    csrfName: "",
    csrfHash: "",
    token: "",
    current: ref({
        page: "",
        data: {}
    }),
    pageTitle: "Unudulmazlar",
    // footerPowered: "© 2021 Company, Inc. Bütün hüquqlar qorunur.",
    footerPowered: 'Powerd By <a href="https://azetechlab.az" class="text-info mx-2"><img src="/azetechlab_logo.png" style="width:120px; background-color:rgba(255,255,255,1);" /></a> ©2022',
    footerDescription: '',

    capitalize: str => str.charAt(0).toUpperCase() + str.slice(1),

    stripTags: str => str != '' ? str.replace(/(<[^>]*>)*/gm, '') : '',


    hasClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) return true;
        else return false;
    },
    setClientData: (name, value) => {
        if (typeof (Storage) !== 'undefined') {
            localStorage.setItem(name, value);
            return true;
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    getClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) {
            return localStorage.getItem(name);
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    removeClientData: (name) => {
        if (typeof (Storage) !== 'undefined' && localStorage[name]) {
            localStorage.removeItem(name);
            return true;
        }
        else {
            return false;
            // Sorry! No Web Storage support..
        }
    },
    is_mobile: () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return true;//"Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return true;//"Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;//"iOS";
        }

        return false;
    },

    // sendData: (opt = null) => {
    //     var th = this;
    //     if (opt != null && opt.url !== "undefined" && opt.url != "")
    //         axios({
    //             url: opt.url,
    //             method: opt.method || "post",
    //             // baseURL: !opt.url.match(/^http/) ? "http://localhost:8090/az" : "",
    //             data: opt.data || {},
    //             // crossDomain: true,
    //             headers: opt.header || {
    //                 "Content-Type": opt.contentType || "multipart/form-data",
    //                 "Authorization": this._TOKEN,
    //                 "X-Requested-With": "XMLHttpRequest",
    //                 "X-CSRF-TOKEN": document.getElementsByName('X-CSRF-TOKEN')[0].content,
    //             },
    //             // config: {
    //             //     headers: {
    //             //         // 'Content-Type': 'multipart/form-data',
    //             //         "Access-Control-Allow-Origin": "http://localhost:8080",
    //             //     }
    //             // },
    //             // withCredentials: false,
    //         }).then((response) => {
    //             if (response.data && response.data.token && response.data.token != "") {
    //                 this._TOKEN = response.data.token;
    //             }
    //             if (opt.success !== "undefined" && opt.success != null)
    //                 opt.success(response, th);
    //             // else
    //             //     console.log(response);
    //         }).catch((error) => {
    //             let resp = error.response;
    //             // if (resp.data && resp.data.msg && resp.data.msg == "Expired") {
    //             //     this.refreshToken();
    //             // }
    //             if (opt.error !== "undefined" && opt.error != null)
    //                 opt.error(resp, th);
    //             // else
    //             //     console.log(resp);
    //         });
    // },

}

export const ajax = axios.create({
    baseURL: G.apiBaseUrl,
    headers: {
        "Accept": 'application/json',
        "Content-Type": "application/json; charset=UTF-8",

        // "Access-Control-Allow-Origin": "http://localhost:8080",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization"
        // "csrfName": `${G.csrfName}`,
        // "csrfHash": `${G.csrfHash}`,
        // "Authorization": `Bearer G.token` || "",
        // "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: false,
});

// // before a request is made start the nprogress
// axios.interceptors.request.use(config => {
//     NProgress.start()
//     return config
// }, error => {
//     NProgress.done()
//     // return error
// }
// )

// // before a response is returned stop nprogress
// axios.interceptors.response.use(response => {
//     NProgress.done()
//     return response
// }, error => {
//     NProgress.done()
//     // return error
// })
