import { createStore } from 'vuex';
import { G } from '@/_common';

export default createStore({
    state: () => {
        return {
            lang: 'az',
            translations: {},
            advancedFilterStatus: 0,
        }
    },
    getters: {
        getLang: (state) => () => {
            return state.lang;
        },
        getTranslations: (state) => () => {
            return state.translations;
        },
        getAdvancedFilterStatus: (state) => () => {
            return state.advancedFilterStatus;
        },

        // replaceLangStr: (state) => (txt) => {
        //     return txt.replace('{lang}', state.lang);
        // },

        // Get API Links
        getLanguagesLink: (state) => () => {
            return G.api.languages.replace('{lang}', state.lang);
        },
        getMenusLink: (state) => () => {
            return G.api.menus.replace('{lang}', state.lang);
        },
        getTranslationsLink: (state) => () => {
            return G.api.translations.replace('{lang}', state.lang);
        },

        getChronology44Link: (state) => () => {
            return G.api.chronology44days.replace('{lang}', state.lang);
        },
        getChronologyTimesLink: (state) => () => {
            return G.api.chronologyTimes.replace('{lang}', state.lang);
        },
        getChronologyContentLink: (state) => () => {
            return G.api.chronologyContent.replace('{lang}', state.lang);
        },

        getSoldierPlacesLink: (state) => () => {
            return G.api.soldierPlaces.replace('{lang}', state.lang);
        },
        getRandomSoldierLink: (state) => () => {
            return G.api.soldierRandom.replace('{lang}', state.lang);
        },
        getSoldierFullNameLink: (state) => () => {
            return G.api.soldierFullName.replace('{lang}', state.lang);
        },
        getSoldierBirthdayLink: (state) => () => {
            return G.api.soldierBirthday.replace('{lang}', state.lang);
        },
        getSoldierDetailLink: (state) => (id) => {
            return G.api.soldierDetail.replace('{lang}', state.lang);
        },
        getSoldierFilterLink: (state) => (id) => {
            return G.api.soldierFilter.replace('{lang}', state.lang);
        },
        getSoldierTypesLink: (state) => (id) => {
            return G.api.soldierTypes.replace('{lang}', state.lang);
        },
        getSoldierBattlePlaceLink: (state) => (soldier_type_id) => {
            return G.api.soldierBattlePlace.replace('{lang}', state.lang).replace('{soldier_type_id}', soldier_type_id);
        },
        getSoldierRanksLink: (state) => (id) => {
            return G.api.soldierRanks.replace('{lang}', state.lang);
        },

        getSoldierBirthLink: (state) => (id) => {
            return G.api.soldierBirthPlace.replace('{lang}', state.lang);
        },
        getSoldierMedalLink: (state) => (id) => {
            return G.api.soldierMedals.replace('{lang}', state.lang);
        },
        getSoldierFamilyLink: (state) => (id) => {
            return G.api.soldierFamily.replace('{lang}', state.lang);
        },
        getSoldierEducationLink: (state) => (id) => {
            return G.api.soldierEducation.replace('{lang}', state.lang);
        },
        getSoldierTroopLink: (state) => (id) => {
            return G.api.soldierTroop.replace('{lang}', state.lang);
        },

        getFooterUsefulLink: (state) => () => {
            return G.api.footerUsefulLinks.replace('{lang}', state.lang);
        },
        getFooterContactInfoLink: (state) => () => {
            return G.api.footerContactInfo.replace('{lang}', state.lang);
        },

        getContactPostLink: () => () => {
            return G.api.contact; //.replace('{lang}', state.lang);
        },

        getAboutLink: (state) => () => {
            return G.api.about_us.replace('{lang}', state.lang);
        },
    },
    mutations: {
        setLang(state, lang) {
            if (lang === undefined || lang == '') lang = 'az';
            state.lang = lang;
        },
        setTranslations(state, tr) {
            if (tr !== undefined)
                state.translations = tr;
        },
        setAdvancedFilterStatus(state, status) {
            if (status !== undefined)
                state.advancedFilterStatus = status;
        },
    },
    actions: {
        setLang({ commit }, lang) {
            if (lang === undefined || lang == '') lang = 'az';
            commit('setLang', lang);
        },
        setTranslations({ commit }, tr) {
            if (tr !== undefined)
                commit('setTranslations', tr);
        },
        setAdvancedFilterStatus({ commit }, status) {
            if (status !== undefined)
                commit('setAdvancedFilterStatus', status);
        },
    }
});

// // access a mutation
// increment: () => store.commit('increment'),
// // access an action
// asyncIncrement: () => store.dispatch('asyncIncrement', data)