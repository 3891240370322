<template>
    <router-view :key="$route.path" />
</template>

<script>
    import {ref} from 'vue';
    // import { useMeta } from 'vue-meta';
    import { useHead } from "@vueuse/head";

    export default {
        name: "App",
        components: {},
        setup() {
            useHead({
                title: 'Unudulmazlar',
                titleTemplate: null,
                htmlAttrs:{
                    lang: 'az-AZ'
                },
                meta: [
                    // { charset: 'utf-8' },
                    { vmid: 'main', property: 'og:title', content: 'unudulmazlar.az' },
                    { vmid: 'main', property: 'og:site_name', content: 'unudulmazlar.az' },
                    { vmid: 'main', property: 'og:description', content: 'unudulmazlar.az, Azərbaycan qəhrəmanları, Şəhidlərimiz, Azərbaycan şəhidləri, Vətən müharibəsi şəhidləri, 44 gün Vətən müharibəsi' },
                    { vmid: 'main', property: 'og:image', itemprop: 'image', content: 'https://unudulmazlar.az/favicon.png' },
                    { vmid: 'main', property: 'og:url', content: 'https://unudulmazlar.az/' },
                    { vmid: 'main', property: 'og:type', content: 'unudulmazlar.az' },
                    { vmid: 'main', property: 'og:image:width', content: '256' },
                    { vmid: 'main', property: 'og:image:height', content: '255' },

                    { vmid: 'main', property: 'twitter:title', content: 'unudulmazlar.az' },
                    { vmid: 'main', property: 'twitter:description', content: 'unudulmazlar.az, Azərbaycan qəhrəmanları, Şəhidlərimiz, Azərbaycan şəhidləri, Vətən müharibəsi şəhidləri, 44 gün Vətən müharibəsi' },
                    { vmid: 'main', property: 'twitter:image', content: 'https://unudulmazlar.az/favicon.png' },
                    { vmid: 'main', property: 'twitter:card', content: 'summary_large_image' },

                ]
            });
            return {};
        },
    };
</script>

<style lang="scss" >
    @import "~nprogress/nprogress.css";
</style>
