import { createApp } from 'vue';
// import VueMeta from 'vue-meta';
// import {createMetaManager} from 'vue-meta';
import { createHead } from "@vueuse/head";


import router from '@/router';
import store from '@/store';

import { dragscrollNext } from "vue-dragscroll";

import App from '@/App.vue'

import "@/assets/style/style.min.css";
import "@/assets/style/carousel.min.css";

import "@/assets/script/site.min.js";

export const ap = createApp(App).use(router).use(store);

// ap.use(VueMeta, {
//     // optional pluginOptions
//     refreshOnceOnNavigation: true,
//     keyName: 'head'
// });
// ap.use(createMetaManager());
ap.use(createHead());



ap.directive('dragscroll', dragscrollNext);

ap.config.globalProperties.$filters = {
    formatDate: value => {
        if( value ){
            let d = value.split('-');
            // let m = new Date(0,d[1],0).toLocaleDateString(store.state.lang, {month: 'short'});
            return `${d[2]}.${d[1]}.${d[0]}`;
        }
        else return value;
        // new Date(value).toLocaleDateString(store.state.lang, {
        //     year: 'numeric',
        //     month: 'short',
        //     day: 'numeric'
        // }),
    },

    replace: (str, txt1, txt2) => {
        let re = RegExp(txt1, "img");
        return str.replace(re, txt2);
    },

    capitalize: str => str.charAt(0).toUpperCase() + str.slice(1),

    stripTags: str => str.replace(/(<[^>]*>)*/gm, ''),
}

// ap.config.errorHandler = () => null;
// ap.config.warnHandler = () => null;


// router.isReady();

ap.mount('#main');

// App.mixin({
//     created() {
//         console.log('[created] ' + this.$options.name)
//     },
// });
